import type { FunctionComponent } from 'react'

import { Container } from '../../components/layout/grid'
import type { FormSectionFragment } from '../../graphql/fragments/FormSectionFragment.graphql'
import type { CustomFieldsetId } from '../../graphql/hooks/form-page'
import { Fieldset } from '../fieldset'
import { Submit } from '../forms/submit'
import styles from './form-section.module.scss'

const FormSection: FunctionComponent<
    Omit<FormSectionFragment, 'type' | 'sys'>
> = ({ fieldset, submit }) => {
    if (!fieldset?.customId) return null

    return (
        <div className={styles.section} id={fieldset.customId}>
            <Container>
                <div className={styles['form-section']}>
                    <Fieldset
                        {...fieldset}
                        customId={fieldset.customId as CustomFieldsetId}
                        renderInsideGrid={false}
                        section={fieldset.section}
                        renderSubmit={(control) => (
                            <Submit
                                control={control}
                                {...submit}
                                order='default'
                            />
                        )}
                    />
                </div>
            </Container>
        </div>
    )
}

export default FormSection
